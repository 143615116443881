@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

:root {
  --primary-color: #3498db;
  --secondary-color: #aedff7;
  --contrast-color: #ea9b47;
  --background-color: #ffffff;
  --font-primary: 'Open Sans', sans-serif;

}

/* Client Portal */
.otherInputBoxDrinkCP {
  border: none;
  font-size: calc(10px + 1.5vmin);
  border-bottom: 2px solid var(--contrast-color);
  background-color: white;
  outline: none;
  text-align: left;
  text-align-last: left;
  color: black;
  width: 100%;
  
  

}
select.otherInputBoxDrinkCP {
  border: none;
  font-size: calc(10px + 1.5vmin);
  border-bottom: 2px solid var(--contrast-color);
  background-color: white;
  outline: none;
  min-width: 63%;
  text-align: left;
  color: black;

}
.otherInputBoxDrinkCPFont {
  border: none;
  font-size: calc(10px + 1.5vmin);
  border-bottom: 2px solid var(--contrast-color);
  background-color: white;
  outline: none;
  text-align: left;
  text-align-last: left;
  color: black;
  width: 100%;
  margin-left: -20%;
  

}
select.otherInputBoxDrinkCPFont {
  border: none;
  font-size: calc(10px + 1.5vmin);
  border-bottom: 2px solid var(--contrast-color);
  background-color: white;
  outline: none;
  min-width: 63%;
  text-align: left;
  color: black;

}

/* Each label & input on one line */
.formRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.formRow label {
  min-width: 140px; /* Adjust as desired */
  margin-right: 10px;
}

/* Sub-section for each delivery method */
.methodSection {
  margin: 15px 0;
}
/* Show circle X on parent hover; assume parent has position: relative. */
/* The parent container each time we have an X button. */
/* Category "X" in upper-left */
.deleteCircleCategory {
  position: absolute;
  top: 1px;
  left: 1px;

  background-color: #aedff7; /* or var(--secondary-color) if you prefer */
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: none; /* hidden by default */
  font-size: 11px;
  
  /* Increase padding for a larger clickable area */
  width: 6px;
  height: 6px;
  padding: 4px; /* bigger than the default 4px */
  
  align-items: center;
  justify-content: center;
}

/* The rest of your classes remain unchanged */

/* Example for item "X" on the upper-right */
.deleteCircle {
  position: absolute;
  top: 5px;
  right: 5px;
  
  background-color: #aedff7;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 11px;
  width: 6px;
  height: 6px;
  padding: 4px;
  align-items: center;
  justify-content: center;
}

.parentContainer {
  position: relative;
}

.parentContainer:hover .deleteCircle,
.parentContainer:hover .deleteCircleCategory {
  display: flex;
}

.deleteCircleCategory:hover,
.deleteCircle:hover {
  background-color: #ea9b47;
}





.clientH3 {
  Margin:1px;
}


/* Main */

body,
input,
button,
h1,
h2,
h3,
h5 {
  font-family: var(--font-primary);
  text-align: center;
  background-color: var(--background-color);
  color: #2C3E50;

}

body {
  font-size: calc(10px + 1.5vmin);
}

h1,
h2,
h3 {
color: var(--primary-color);
  border-bottom: 2px solid var(--contrast-color);
  display: inline-block;
  max-width: 95%;

}

@media (max-width: 600px) {

  h1,
  h2,
  h3 {
    font-size: 1.5em;
  }
}

@media (min-width: 185px) {
  .icon-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    border-radius: 10px;
    height: auto;
    padding-bottom: 0px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 12px;
    padding: 0px;
  }
}

.homeHeader p {
  margin: 5px 0; /* 5px of vertical space between paragraphs */
}


button {
  background-color: #D35400;
  font-size: calc(10px + 1.5vmin);
  color: #FFFFFF;

  border: none;

  /* transition: background-color 0.3s, color 0.3s; */
  cursor: pointer;
  border-radius: 5px;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

button:hover {
  background-color: #2C3E50;

  color: #FEBE75;

}


.App {
  text-align: center;
  max-width: 400px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.hidden {
  display: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: left;
  font-size: calc(10px + 1.5vmin);
  color: #2C3E50;

}

.App-wrapper {
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.App-link {
  color: #2C3E50;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.guest-count-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.guest-label {
  display: flex;
  align-items: center;
}

.guest-label:first-child {
  margin-right: 2px;
}

.guest-label:last-child {
  margin-left: 20px;
}

.guest-label span {
  margin-right: 5px;
}

.guest-input {
color: var(--primary-color);
  border: none;
  border-bottom: 2px solid var(--secondary-color);
  background-color: transparent;
  width: 80px;
  font-size: 100%;
  outline: none;
  margin-left: 0;
}

.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;

}


.slider-container {
  margin: 1.4rem auto;
  position: relative;
}

.slider-container label {

  text-align: center;

}


.modal-overlay {
  position: fixed;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  width: 70%;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
}

/* Scrollable content inside modal */
.terms-scroll {
  max-height: 60vh; /* Ensures scrolling if content exceeds */
  overflow-y: auto;
  padding-right: 10px;
}

/* Close button */
.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Ensure button doesn't move */
.close-button-bottom {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  background: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.close-button-bottom:hover {
  background: #0056b3;
}
.error-text {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}



.slider-container label {

  text-align: center;
}

.custom-input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
color: var(--primary-color);
  font-size: 1em;
  text-align: left;

}

.custom-inputAccomodationsDrink {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
color: var(--primary-color);
  font-size: .8em;
  text-align: right;
  margin-right: 1px;
  margin-bottom: 5px;

}

.custom-inputAccomodations {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
color: var(--primary-color);
  font-size: .7em;
  text-align: right;
  margin-right: 1px;
  font-size: calc(10px + 1.5vmin);
}



.input-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-container button {
  border: none;
  background-color: transparent;
  font-size: .8em;
  cursor: pointer;
}

.arrow-button-increase {
  color: #ea9b47;
}

.arrow-button-decrease:hover {
color: var(--secondary-color);
}

.arrow-button-decrease {
color: var(--primary-color);
}

.input-container button:active {
color: var(--secondary-color);
}

.input-container button:focus {
  outline: none;
}

.input-container input {
  width: 60px;
  text-align: left;
}





.presentation-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.icon-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  border-radius: 10px;
  height: auto;
  padding-bottom: 0px;
  padding: 3px;
  padding-bottom: 0px;
  margin-right: 12px;

  margin-left: 12px;

  margin-bottom: 12px;

}

.icon-container.selected .icon-label {
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  padding: 3px;
}

.dimmed {
  opacity: 0.7;
}

.icon-container:hover {
  transform: scale(1.05);
}

.presentation-icon {
  height: auto;
  object-fit: contain;
  background-color: transparent;
  align-self: center;
  margin: 0;
}

.icon-label {
  display: flex;
  margin-top: 3px;
  white-space: normal;
  text-align: center;
}




/* input[type="radio"]+label {
  position: relative;
  padding-left: 25px;
  padding-bottom: 2px;
  padding-top: 2px;
  cursor: pointer;
} */



/* input[type="radio"]+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 58%;
  left: 4px;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
} */


/* input[type="radio"]:checked+label:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 58%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--primary-color);
}


.small-radio+label {
  position: relative;
  padding-left: 20px;
  
  padding-bottom: 2px;
  padding-top: 2px;
  cursor: pointer;
}

.small-radio+label:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
}

.small-radio:checked+label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  width: 6px !important;
  height: 6px !important;
  border-radius: 50%;
  background-color: var(--primary-color);
} */

.spacer {
  height: 50%;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.button-container {
  display: flex;
  justify-content: center;

}

.field {
  margin-bottom: 10px;
}

.field input {
  width: 100%;
}

.presentationDropDown {
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 10px;
  align-items: flex-end;
  display: flex;
  justify-content: end;
  justify-items: end;
  margin-left: 0px;
  margin-right: 17px;
}

.otherInputBox {
  border: none;

  border-bottom: 2px solid var(--contrast-color);


  outline: none;

  width: 110px;
  margin-right: 1px;
  margin-left: -55px;
}

.venueInput {
  border: none;
  border-bottom: 2px solid var(--contrast-color);
  outline: none;
  color: black;
  font-size: calc(10px + 1.5vmin);
}

.venueInput::placeholder {
  color: black;
  opacity: 1;
}

.notesInputBoxDrink {
  border: none;
  font-size: calc(10px + 1.5vmin);
  border-bottom: 2px solid var(--contrast-color);
  text-align: center;


  outline: none;

  margin-top: 20px;
}

.otherInputBoxDrinkRD {
  border: none;
  background-color: white;
  /* Set your desired background color here */

  border-bottom: 2px solid var(--contrast-color);


  outline: none;

  text-align: center;
  font-size: calc(10px + 1.5vmin);
  min-width: 100%;

}

@media screen and (max-width: 768px) {
  .otherInputBoxDrinkRD {
    text-align: center;
    text-align-last: center;
    /* This tries to center align the text in the dropdown on mobile */
  }

  select.otherInputBoxDrinkRD {
    background-color: white;
    /* Set your desired background color here */
    color: black;
  }
}

.otherInputBoxDrink {
  border: none;
  font-size: calc(10px + 1.5vmin);
  border-bottom: 2px solid var(--contrast-color);
  background-color: white;
  outline: none;
  text-align: center;
  text-align-last: center;
  color: black;
  width:100%

}
select.otherInputBoxDrink {
  border: none;
  font-size: calc(10px + 1.5vmin);
  border-bottom: 2px solid var(--contrast-color);
  background-color: white;
  outline: none;
  min-width: 63%;
  text-align: center;
  color: black;

}

.otherInputBoxDrinkFoodService {
  border: none;
  font-size: calc(10px + 1.5vmin);
  border-bottom: 2px solid var(--contrast-color);
  background-color: white;
  outline: none;
  width: calc(100% - 10px); /* Adjust width based on the longest option */
  text-align: left;
  resize: none;
  min-width: 150px; /* Ensure it doesn't get too narrow */
  overflow: hidden; /* Hide the scrollbar */
  word-wrap: break-word
}
.otherInputBoxFood {
  border: none;

  border-bottom: 2px solid var(--contrast-color);


  outline: none;

  margin-top: 10px;
}

.otherInputBox:focus {
  border-bottom-color: #d88530;

}

h2 {
  margin-bottom: 8px;

}



h5 {
  margin-bottom: 5px;
  margin-top: 5px;
}

input[type="number"] {
  width: 6ch;
  box-sizing: border-box;
}

input.large-number {
  width: 6ch;
  height: 2.2ch;

  box-sizing: border-box;
}

input.food-input {
  width: 4.5ch;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  text-align: center;
color: var(--primary-color);
  font-size: calc(10px + 1.5vmin);
}

.food-options {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  justify-items: end;
  margin-right: 39%;

}


.nonAlcoholic-options {
  text-align: left;
  padding-left: 26%;
}

.nonAlcoholic-options label {
  display: inline-block;
  width: 52px;
}

.nonAlcoholic-options input[type="number"] {
  height: 2.2ch;
  width: 6ch;
}

.Alcoholic-options {
  text-align: left;
  padding-left: 19.2%;
}

.Alcoholic-options label {
  display: inline-block;
  width: 52px;
}

.Alcoholic-options input[type="number"] {
  height: 2.2ch;
  width: 6ch;
}

.decreased-margin {
  margin-left: 17%;
}

.decreaseHowMany {
  margin-left: 11%;
}

.decreaseHowManyTwo {
  margin-left: -6%;
}

.dropdown-separator {
  border-bottom: 1px solid #ccc;

  margin: 10px 0;

}

.alcoholicMargin {
  position: relative;
  left: 50%;
  transform: translateX(-56%);

}

.nonAlcohlicMargin {
  display: flex;

  justify-content: center;

  align-items: center;

  flex-direction: column;

  height: 100%;

  width: 100%;

}
.venue-field {
  margin-bottom: 10px;
}

.time-input-container {
  display: flex;
  align-items: center;
}
.input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.sign-up-button {
  margin-bottom: 40px;
}
.input-groupPRESENTATION {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.four-digit-input {
  width: 10ch;

}

.presentation-box {
  display: -webkit-inline-box;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  font-size: calc(10px + 1.5vmin);
}
.presentation-box-Buffet {
  display: -webkit-inline-box;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  font-size: calc(7px + 1.5vmin);
}
.presentation-boxBuffetSubCatergory {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-right: -10%;
}

.presentation-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  min-width: 150px;
  padding: 0 10px;
  word-wrap: break-word;
}

.presentation-description label {
  text-align: center;
  white-space: normal;
}

.presentation-box label {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

@media screen and (max-width: 230px) {
  .presentation-box label span {
    flex-basis: 100%;
  }
}

.presentation-box .radioOption label {
  display: flex;
  flex-wrap: wrap;
}

.presentation-box .radioOption label span {
  white-space: nowrap;
}

@media screen and (max-width: 230px) {
  .presentation-box .radioOption label span {
    flex-basis: 100%;
  }
}

.presentation-input {
  width: 100%;
  max-width: 150px;
  box-sizing: border-box;
}

.presentation-box label {
  white-space: nowrap;
  text-align: left;
  margin-bottom: 5px;
}



.buffetOptionDropDown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.radioOption {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.radioOption input[type="radio"] {
  margin-right: 5px;
}


.buffetOptionDropDown label {
  margin: 5px;
}


.buffetOptionDropDown input[type="radio"] {
  margin-right: 5px;
}



.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.decrement-btn {
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: .9rem;
  color: #ea9b47;
  outline: none;
  transition: color 0.3s;
  padding: 0;
  display: flex;
  justify-content: center;
}

.increment-btn {
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: .9rem;
color: var(--primary-color);
  outline: none;
  transition: color 0.3s;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

}

.increment-btn:hover,
.decrement-btn:hover {
  background: none;

color: var(--secondary-color);
}

.increment-btn:active,
.decrement-btn:active {
color: var(--secondary-color);
  background: none;
}



.presentation-input {
  border: none;
  outline: none;
  background-color: transparent;
  width: 3ch;
  font-size: calc(10px + 1.5vmin);
  text-align: left;
color: var(--primary-color);
}

.highlighted-text {
  text-decoration: underline;
color: var(--primary-color);
}



.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.margin-bottom {
  margin-bottom: 15px;
}

.option-item {
  font-size: 200rem;

  margin-bottom: 80px;

}

.select-container {
  display: grid;
  justify-items: end;
  margin-left: -90px;
  margin-right: -16px;
  margin-bottom: 0px;
  transform: scale(0.75);
}

.selfServe {
  font-size: 3rem;
}

.buffetOptionDropDown {
  margin-left: -1200px;
  margin-bottom: 0px;
  margin-top: 0px;
  display: flex;
  justify-items: flex-end;
  flex-direction: column;
  font-size: .8rem;
}

.dropdownContainer {
  font-family: var(--font-primary);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 0px;
  color: black;

}

.customDropdown {
  transform: scale(0.8);
  transform-origin: left;
  border: 1px solid #ccc;
  box-shadow: none;
  padding: 2px;
  color: black;
  cursor: pointer;
  border-radius: 4px;
  font-size: calc(10px + 1.5vmin);
  font-family: var(--font-primary);

}

.accommodation-checkbox {
  flex-basis: calc(50% - 10px);

  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: calc(10px + 1.5vmin);

}

.customDropdownAccomodations {
  transform: scale(0.8);
  transform-origin: left;
  border: 1px solid #ccc;
  box-shadow: none;
  padding: 4px 2px;
  color: black;
  cursor: pointer;
  border-radius: 4px;
  font-size: calc(10px + 1vmin);
  background-color: #fff;
}

.customDropdownTimeline {
  transform: scale(0.8);
  transform-origin: left;
  border: 1px solid #ccc;
  box-shadow: none;
  padding: 4px 2px;
  color: black;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;
  font-size: calc(10px + 1vmin);
  margin-top: 5px;
  margin-bottom: 5px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.customDropdownTimelineHM {
  font-size: calc(10px + 1.5vmin);
  transform-origin: right;
  border: 1px solid #ccc;
  box-shadow: none;
  color: black;
  cursor: pointer;
  border-radius: 4px;

}

.eveFewAccomodation {
  margin-bottom: 10px;
}

.customDropdownCutlery {
  transform: scale(0.8);
  transform-origin: center;
  border: 1px solid #ccc;
  box-shadow: none;
  padding: 2px;
  color: black;
  cursor: pointer;
  border-radius: 4px;

}
.DLeft{
  margin-left: 5px;
}
.provided-by-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Adjusts the number of columns */
}
.provided-by-gridServices {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Adjusts the number of columns */
}
.visually-hidden {
  visibility: hidden;
}
.label-no-wrap {
  white-space: nowrap;
  overflow: visible;
  display: flex;
  align-items: end;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  color: black;
  margin-right: 5%;

}


.customDropdownKitchen {
  transform: scale(0.8);
  transform-origin: center;
  border: 1px solid #ccc;
  box-shadow: none;
  color: black;
  cursor: pointer;
  border-radius: 4px;

}

.addMoreContainer {
  font-size: .8rem;
}

.addMoreContainer::before {
  content: "x";
  display: inline-block;
  opacity: 0;

  margin-right: 1.3ch;

  margin-top: 0px;
}

.beverage-dropdowns {
  flex-wrap: wrap;
  position: relative;

}

.button-margin-left {
  margin-left: -20px;
}

.textareaAutoSize {
  overflow: hidden;
  resize: none;
  min-width: 100px;
}

.beverage-dropdowns> :last-child {

  min-width: 80%;
}


.drinkSelectionContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
}

.centered-header {
  text-align: left;
  margin-left: 45%;
}

.drinkSelectionContainerCutlery {
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.submitButton {
  font-size: large;

}

.drinkCardGC {

  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 130px;

  overflow: hidden;
  position: relative;
  padding-left: 20px;
  padding-top: 20px;
  margin-bottom: 10px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.drinkCardGC:hover {
  transform: scale(1.05);

}

.drinkCard {

  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 130px;

  overflow: hidden;
  position: relative;
  padding-left: 20px;
  padding-top: 20px;
  margin-bottom: 10px;
  padding-right: 5px;
  padding-bottom: 5px;
  margin: 1%;
}

.drinkCard:hover {
  transform: scale(1.05);

}


.drinkCardTimeline {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 130px;
  max-width: 130px;

  overflow: hidden;
  position: relative;
  padding: 20px;
}

.drinkCardTimeline:hover {
  transform: scale(1.05);

}

.accommodations-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;

}

.accommodations-container-food {
  display: grid;
  grid-template-columns: min-content;
  gap: 10px;
  margin-left: 3%;
}

.drinkCardAccomodations {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 30px;
}

.drinkCardAccomodations:hover {
  transform: scale(1.05);

}

.drinkCardGuestFood {
  min-width: 330px;
}
.raw-email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}



.raw-email-textarea {
  width: 100%;
  max-width: 400px; /* Adjust the width as needed */
  height: 500px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}



.drinkCardGuest {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.drinkCardGuest:hover {
  transform: scale(1.05);

}

.drinkCardGuestWPW {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.custom-checkbox {
  justify-self: center; /* Center checkboxes in their cells */
  scale: 1.3;

}
.label-no-wrap {
  grid-column: 1 / 2; /* Label occupies the first column */
}

@media (min-width: 588px) {
  .drinkCardGuestWPW {
    margin-left: -10%;
    min-width: 110%;
  }


}

.drinkCardGuestWPW:hover {
  transform: scale(1.05);

}

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 7px;
}



.dropdownSection {
  display: flex;
  flex-direction: column;
  position: relative;

}


.dropdownSection select {
  padding: 10px 4px 10px 2px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background-color: white;
  /* Set your desired background color here */
  color: black;
}

.dropdownSectionKitchen {
  display: contents;

}


.dropdownSectionKitchen select {
  padding: 10px 4px 10px 2px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-top: 20px;
  background-color: white;
  font-size: calc(10px + 1.5vmin);

}

.removeOption {
  z-index: 1000;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-color: var(--secondary-color);

  color: #fff;
  border-radius: 50%;

  width: 6px;
  height: 6px;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  padding: 4px;
}


.removeOption:hover {
  background-color: #ea9b47;
  display: flex;

}

.drinkButton {
  width: fit-content;
  margin-left: 31%;

}

.removeOptionCategory {
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: pointer;
  background-color: var(--secondary-color);

  color: #fff;
  border-radius: 50%;

  width: 6px;
  height: 6px;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  padding: 4px;
}

.drinkCard:hover .removeOptionCategory {
  display: flex;
  background-color: var(--secondary-color);
}

.reocurringX {
  display: flex;
  align-items: center;
}

.reocurringXFrequency {
  align-items: center;
}

.removeOptionTimeLine {
  top: -4px;
  position: relative;
  cursor: pointer;
  background-color: var(--secondary-color);
  color: #fff;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  padding: 4px;
  display: none;
  margin-left: -17px;
}

.removeOptionTimeLineFrequency {
  position: relative;
  left: 5px;
  cursor: pointer;
  background-color: var(--secondary-color);
  color: #fff;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  padding: 4px;
  display: none;
}

.drinkCardGuest:hover .removeOptionTimeLine {
  display: inline-flex;
}

.drinkCardGuest:hover .removeOptionTimeLineFrequency {
  display: inline-flex;
}

.removeOptionAccomodation {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-color: var(--secondary-color);
  margin-right: 4%;
  margin-top: 4%;
  color: #fff;
  border-radius: 50%;

  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 4px;
}


.removeOptionAccomodation:hover {
  background-color: #ea9b47;
}

.mainDropdown {
  font-size: calc(10px + 1.5vmin);
  border: none;
  outline: none;
  background-color: var(--background-color);
  color: black;
  font-family: var(--font-primary);

}

.mainDropdown.selected {
  color: black;
  width: -webkit-fill-available;
  -webkit-appearance: none;

  -moz-appearance: none;

  appearance: none;
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
}


.required-row {
  margin-top: 5px; /* Adjusts space between "Contact" and "* Required" */
}

.required-text {
  display: block; /* Ensures it takes up its own line */
  font-size: calc(10px + 0.3vmin); /* Change the font size as needed */
}


.plusMore {
  margin-top: 10px;
}
@media (min-width: 1000px) {
  .presentation-icon {
    width: 70%; /* Adjust width when viewport is above 1000px */
  }
}
@media only screen and (min-width: 270px) {

  .select-container {
    display: grid;
    justify-items: end;
    margin-left: -90px;
    margin-right: 0px;
    margin-bottom: 0px;
    transform: scale(0.75);
  }

  .presentationDropDown {
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 10px;
    align-items: flex-end;
    display: flex;
    justify-content: end;
    justify-items: end;
    margin-left: -1200px;
    margin-right: 50px;
  }

  .buffetOptionDropDown {
    margin-left: -120px;
    margin-bottom: 0px;
    margin-top: 0px;
    display: flex;
    justify-items: flex-end;
    flex-direction: column;
    font-size: 1rem;
  }

  input.food-input::placeholder {
    color: #2c3e50;
    /* or any other color you want */
  }

  input.large-number {
    width: 6ch;
    height: 3ch;
    box-sizing: border-box;
    font-size: calc(10px + 1.5vmin);

  }




  .slider-container label {

    text-align: center;
    padding: 0px 2px;


  }



  .icon-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 20%;

    border-radius: 10px;
    height: auto;

    padding-bottom: 0px;
    margin-right: 12px;

    margin-left: 12px;

    margin-bottom: 12px;

    font-size: calc(10px + 1.5vmin);

  }

  .icon-container.selected .icon-label {
    border: 2px solid var(--primary-color);
    border-radius: 10px;

    padding: 3px;

  }

  .icon-container:hover {
    transform: scale(1.05);

  }

  .presentation-icon {

    width: 100%;

    height: 100%;

    object-fit: contain;


    background-color: transparent;
    align-self: center;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
  }

  .icon-label {

    display: flex;
    /* align-items: center;
  justify-content: center; */
    margin-top: 3px;

    white-space: normal;

    text-align: center;

  }





  .nonAlcoholic-options {
    text-align: left;
    padding-left: 110px;
  }

  .nonAlcoholic-options label {
    display: inline-block;
    width: 95px;
  }

  .nonAlcoholic-options input[type="number"] {
    height: 3ch;
    width: 6ch;
  }

  .Alcoholic-options {
    text-align: left;
    padding-left: 87px;
  }

  .Alcoholic-options label {
    display: inline-block;
    width: 95px;
  }

  .Alcoholic-options input[type="number"] {
    height: 3ch;
    width: 6ch;
  }



  .decreaseHowMany {
    margin-left: 46px;
  }

  .decreaseHowManyTwo {
    margin-left: 0%;
  }


}

@media only screen and (min-width: 270px) {
  :root {
    --viewport-width: calc((100vw - 270px) * 0.5);
  }

  .decreased-margin {
    margin-left: var(--viewport-width);
  }
}








@media only screen and (min-width: 400px) {




  .beverage-dropdowns:hover .removeOption {
    display: flex;

  }

  .slider-container {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .decreased-margin {
    margin-left: 15%;
  }

}
/* Home and Waitlist Page */
.home-wrapper, .waitlist-wrapper {
  text-align: center;
  padding: 20px;
}

.home-header {
  background-color: #f8f8f8;
  padding: 20px;
}

.logo {
  width: 150px;
  height: auto;
}

.food-form-section, .waitlist-form {
  margin: 20px auto;
}

.reserve-spot-button {
  background-color: #3498DB;
  color: white;
  padding: 10px 20px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.reserve-spot-button:hover {
  background-color: #2980B9;
}

.waitlist-form input, .waitlist-form textarea {
  width: 80%;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 14px;
}

.waitlist-form button {
  background-color: #3498DB;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.waitlist-form button:hover {
  background-color: #2980B9;
}

/* General Styles */
body {
  font-family: "Segoe UI", Tahoma, sans-serif;
  background-color: #f9fafb;  /* light gray background for contrast */
  color: #333;
  margin: 0;
  padding: 0;
}
.container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 1.5rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

/* Urgency Message */
.urgency-banner {
  background: #fff4e5; /* a very light orange to draw attention */
  border: 1px solid #f5c07b;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1rem;
  color: #d35400;  /* dark orange text for urgency */
}
.urgency-banner strong {
  font-weight: 600;
}
.urgency-banner .spots-count {
  font-size: 1.2em;
  color: #c0392b;  /* a red shade to highlight the number remaining */
}

/* Pricing Options */
.pricing-toggle {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.pricing-toggle button {
  background: #eaeaea;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0;
  cursor: pointer;
  font-weight: 600;
  color: #555;
  border-radius: 4px 4px 0 0;
}
.pricing-toggle button.selected {
  background: #fff;
  color: #000;
  border: 2px solid #4caf50; /* highlight selected plan (green border) */
  border-bottom: none;
  position: relative;
  top: 2px; /* raise it to overlap content box slightly */
}
.pricing-details {
  border: 2px solid #4caf50;
  background: #e8ffed; /* very light green background for the selected pricing box */
  padding: 1rem;
  border-radius: 0 4px 4px 4px;
  text-align: center;
  margin-bottom: 1.5rem;
}
.pricing-details p {
  margin: 0.5rem 0;
}
.price {
  font-size: 1.5rem;
  font-weight: 700;
}
#annual-discount {
  font-size: 0.9rem;
  color: #27ae60; /* dark green text for savings */
}

/* Founder Testimonial */
.testimonial {
  font-style: italic;
  background: #f0f8ff; /* light blue background for a calming, trust vibe */
  border-left: 4px solid #3498db; /* blue border to accentuate */
  padding: 1rem;
  margin: 1.5rem 0;
  border-radius: 4px;
  color: #34495e;
}
.testimonial::before {
  content: "“";
  font-size: 2rem;
  line-height: 0;
  vertical-align: sub;
  color: #3498db;
}
.testimonial::after {
  content: "”";
  font-size: 2rem;
  line-height: 0;
  vertical-align: sub;
  color: #3498db;
}
.testimonial cite {
  display: block;
  text-align: right;
  font-style: normal;
  font-weight: 600;
  margin-top: 0.5rem;
  color: #34495e;
}

/* CTA Button */
.cta-button {
  display: block;
  width: 100%;
  background: #e67e22; /* orange button for urgency and action */
  color: #fff;
  text-align: center;
  font-size: 1.1rem;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  margin: 1rem 0 0.5rem 0;
  transition: background 0.3s ease;
}
.cta-button:hover {
  background: #cf6d17; /* slightly darker on hover */
}
.cta-button:active {
  background: #b85e14;
}
.cta-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* Trust Signals Section */
.trust-signals {
  text-align: center;
  font-size: 0.9rem;
  color: #555;
  margin-top: 1rem;
}
.trust-signals .secure-checkout {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.25rem;
}
.trust-signals .secure-checkout:before {
  content: "🔒";  /* padlock emoji as a simple icon (can replace with image) */
  display: inline-block;
  margin-right: 0.4rem;
}
.trust-signals .money-back {
  margin-bottom: 0.25rem;
}
.trust-signals .money-back:before {
  content: "✅";
  display: inline-block;
  margin-right: 0.4rem;
}
.trust-signals .payment-methods img {
  height: 20px;
  margin: 0 5px;
  vertical-align: middle;
  opacity: 0.8;
}
.trust-signals .payment-methods img:hover {
  opacity: 1;
}

/* Responsive Design */
@media (max-width: 600px) {
  .pricing-toggle {
    flex-direction: column;
  }
  .pricing-toggle button {
    width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 4px;
  }
  .pricing-toggle button.selected {
    border-bottom: 2px solid #4caf50; /* keep bottom border on mobile selected */
    top: 0;
  }
}

/* Styling Guide
Darker Blue: 3498DB
Oragne: ea9b47
light blue: AEDFF7
*/
